import React, {useState} from "react"
import PrimaryButton from "../common/PrimaryButton";
import {DateTimePicker} from "../ui/date-time-picker/date-time-picker";
import {DateValue} from "react-aria";
import {showJetToast} from "../Toast";
import {getLocalTimeZone, ZonedDateTime, fromDate} from "@internationalized/date";
import PropTypes from "prop-types";
import {Turbo} from '@hotwired/turbo-rails'
import GhostButton from "../common/GhostButton";
import {
    AlertDialog, AlertDialogAction, AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "../ui/alert-dialog";
import {Button} from "../ui/button";

const PostSocialContainer = ({publish_short_path, schedule_short_path, video_duration}) => {
    const [postNowConfirmOpen, setPostNowConfirmOpen] = useState(false);
    const [scheduleConfirmOpen, setScheduleConfirmOpen] = useState(false);
    const [showPopover, setShowPopover] = useState(true);
    const [showCalendar, setShowCalendar] = useState(false);
    const [date, setDate] = React.useState<DateValue>(
        fromDate(new Date(), getLocalTimeZone()).add({minutes: 60})
    );
    const [error, setError] = React.useState<string | null>();
    const [scheduleLoading, setScheduleLoading] = React.useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const onDateChange = (date: DateValue) => {
        setDate(date);
        console.log(date);
    }

    const isValidDate = (date: DateValue) => {
        return date.toDate(getLocalTimeZone()) > new Date();
    }

    const showToastView = (message: string) => {
        showJetToast(message);
        setToastMessage(message);
        setShowToast(true);
        setTimeout(function () {
            setShowToast(false);
        }, 3000);
    }

    const onScheduleClick = () => {
        if (date && isValidDate(date)) {
            setScheduleLoading(true);
            fetch(schedule_short_path, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    // @ts-ignore
                    "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
                },
                body: JSON.stringify({scheduled_at: date.toDate("UTC")})
            }).then(() => {
                showToastView("Video scheduled successfully");
                Turbo.visit("/");
                setShowPopover(false);
            }).catch(() => {
                showToastView("Failed to schedule post");
            }).finally(() => {
                setScheduleLoading(false);
            });
            setError(null);
        } else {
            setError("Please select a valid date and time");
            showToastView("Please select a date and time");
        }
    }

    return (
        <div className="flex flex-col w-full">
            <div className="text-textCaption text-center font-medium">Post to Social Media</div>
            {showPopover ? (
                <dialog className="rounded relative top-4 shadow-md w-full bg-surface p-4 mb-10" open>
                    <div className="dialog-content flex gap-3 flex-col font-medium">
                        <div className="text-lg flex shadow-md bg-white p-2 rounded cursor-pointer items-center justify-center">
                            <AlertDialog open={postNowConfirmOpen} onOpenChange={setPostNowConfirmOpen}>
                                <AlertDialogTrigger asChild>
                                    <Button variant="outline">Post Now</Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Post Now?</AlertDialogTitle>
                                        <AlertDialogDescription>
                                            This will post the video to different connected social media accounts as soon as possible (Usually takes few minutes). Are you sure you want to post now?
                                            <div className="mt-4">You can edit the title and social media caption before posting by clicking on the edit button on video created screen.</div>
                                            <div className="mt-4">You can update video privacy/visibility in page settings by editing your page in settings.</div>
                                        </AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction onClick={() => setPostNowConfirmOpen(false)}>
                                            <a data-turbo-method="post" href={publish_short_path}>Post</a>
                                        </AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                        </div>
                        <div className="text-md text-textCaption">Post Later</div>
                        <DateTimePicker granularity={"minute"} onChange={onDateChange} value={date}/>
                        <div className="text-lg flex justify-center items-center shadow-md bg-white p-2 rounded cursor-pointer">
                            <AlertDialog open={scheduleConfirmOpen} onOpenChange={setScheduleConfirmOpen}>
                                <AlertDialogTrigger asChild>
                                    <Button variant="outline">Schedule</Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Schedule for later?</AlertDialogTitle>
                                        <AlertDialogDescription>
                                            This will schedule to post the video to different connected social media
                                            accounts. Are you sure you want to schedule the video?
                                            <div className="mt-4">You can edit the title and social media caption before
                                                posting by clicking on the edit button on video created screen.</div>
                                            <div className="mt-4">You can update video privacy/visibility in page settings by editing
                                                your page in settings.
                                            </div>
                                        </AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction onClick={() => {
                                            setPostNowConfirmOpen(false);
                                            onScheduleClick();
                                        }}>
                                            Schedule
                                        </AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                        </div>
                    </div>
                    {showToast ? <dialog className="fixed top-10 rounded-lg px-5 py-2 bg-surface z-[80]" open={true}>
                        <div className="text-md">{toastMessage}</div>
                    </dialog>: null}
                </dialog>
            ): null}
            {error ? <div className="text-red-500">{error}</div>: null}
        </div>
    )
}

PostSocialContainer.propTypes = {
    publish_short_path: PropTypes.string.isRequired,
    schedule_short_path: PropTypes.string.isRequired,
    video_duration: PropTypes.number.isRequired
};

export default PostSocialContainer
